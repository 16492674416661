import E from 'wangeditor';
class wEditor {
    constructor(ele, url,menus = []) {
        this.editor = new E(ele);
        this.uploadUrl = url;
        this.editor.customConfig.emotions = [
            {
                // tab 的标题
                title: '默认',
                // type -> 'emoji' / 'image'
                type: 'image',
                // content -> 数组
                content: [
                    {
                        alt: '[微笑]',
                        src: require("../assets/emoticons/0.gif")
                    },
                    {
                        alt: '[瘪嘴]',
                        src: require("../assets/emoticons/1.gif")
                    },
                    {
                        alt: '[色]',
                        src: require("../assets/emoticons/2.gif")
                    },
                    {
                        alt: '[发呆]',
                        src: require("../assets/emoticons/3.gif")
                    },
                    {
                        alt: '[得意]',
                        src: require("../assets/emoticons/4.gif")
                    },
                    {
                        alt: '[流泪]',
                        src: require("../assets/emoticons/5.gif")
                    },
                    {
                        alt: '[害羞]',
                        src: require("../assets/emoticons/6.gif")
                    },
                    {
                        alt: '[闭嘴]',
                        src: require("../assets/emoticons/7.gif")
                    },
                    {
                        alt: '[睡]',
                        src: require("../assets/emoticons/8.gif")
                    },
                    {
                        alt: '[大哭]',
                        src: require("../assets/emoticons/9.gif")
                    },
                    {
                        alt: '[尴尬]',
                        src: require("../assets/emoticons/10.gif")
                    },
                    {
                        alt: '[发怒]',
                        src: require("../assets/emoticons/11.gif")
                    },
                    {
                        alt: '[调皮]',
                        src: require("../assets/emoticons/12.gif")
                    },
                    {
                        alt: '[呲牙]',
                        src: require("../assets/emoticons/13.gif")
                    },
                    {
                        alt: '[惊讶]',
                        src: require("../assets/emoticons/14.gif")
                    },
                    {
                        alt: '[难过]',
                        src: require("../assets/emoticons/15.gif")
                    },
                    {
                        alt: '[酷]',
                        src: require("../assets/emoticons/16.gif")
                    },
                    {
                        alt: '[冷汗]',
                        src: require("../assets/emoticons/17.gif")
                    },
                    {
                        alt: '[抓狂]',
                        src: require("../assets/emoticons/18.gif")
                    },
                    {
                        alt: '[吐]',
                        src: require("../assets/emoticons/19.gif")
                    },
                    {
                        alt: '[偷笑]',
                        src: require("../assets/emoticons/20.gif")
                    },
                    {
                        alt: '[愉快]',
                        src: require("../assets/emoticons/21.gif")
                    },
                    {
                        alt: '[白眼]',
                        src: require("../assets/emoticons/22.gif")
                    },
                    {
                        alt: '[傲慢]',
                        src: require("../assets/emoticons/23.gif")
                    },
                    {
                        alt: '[饥饿]',
                        src: require("../assets/emoticons/24.gif")
                    },
                    {
                        alt: '[困]',
                        src: require("../assets/emoticons/25.gif")
                    },
                    {
                        alt: '[惊恐]',
                        src: require("../assets/emoticons/26.gif")
                    },
                    {
                        alt: '[流汗]',
                        src: require("../assets/emoticons/27.gif")
                    },
                    {
                        alt: '[憨笑]',
                        src: require("../assets/emoticons/28.gif")
                    },
                    {
                        alt: '[悠闲]',
                        src: require("../assets/emoticons/29.gif")
                    },
                    {
                        alt: '[奋斗]',
                        src: require("../assets/emoticons/30.gif")
                    },
                    {
                        alt: '[咒骂]',
                        src: require("../assets/emoticons/31.gif")
                    },
                    {
                        alt: '[疑问]',
                        src: require("../assets/emoticons/32.gif")
                    },
                    {
                        alt: '[嘘]',
                        src: require("../assets/emoticons/33.gif")
                    },
                    {
                        alt: '[晕]',
                        src: require("../assets/emoticons/34.gif")
                    },
                    {
                        alt: '[疯了]',
                        src: require("../assets/emoticons/35.gif")
                    },
                    {
                        alt: '[哀]',
                        src: require("../assets/emoticons/36.gif")
                    },
                    {
                        alt: '[骷髅]',
                        src: require("../assets/emoticons/37.gif")
                    },
                    {
                        alt: '[敲打]',
                        src: require("../assets/emoticons/38.gif")
                    },
                    {
                        alt: '[再见]',
                        src: require("../assets/emoticons/39.gif")
                    },
                    {
                        alt: '[擦汗]',
                        src: require("../assets/emoticons/40.gif")
                    },
                    {
                        alt: '[抠鼻]',
                        src: require("../assets/emoticons/41.gif")
                    },
                    {
                        alt: '[鼓掌]',
                        src: require("../assets/emoticons/42.gif")
                    },
                    {
                        alt: '[糗大了]',
                        src: require("../assets/emoticons/43.gif")
                    },
                    {
                        alt: '[坏笑]',
                        src: require("../assets/emoticons/44.gif")
                    },
                    {
                        alt: '[左哼哼]',
                        src: require("../assets/emoticons/45.gif")
                    },
                    {
                        alt: '[右哼哼]',
                        src: require("../assets/emoticons/46.gif")
                    },
                    {
                        alt: '[哈欠]',
                        src: require("../assets/emoticons/47.gif")
                    },
                    {
                        alt: '[鄙视]',
                        src: require("../assets/emoticons/48.gif")
                    },
                    {
                        alt: '[委屈]',
                        src: require("../assets/emoticons/49.gif")
                    },
                    {
                        alt: '[快哭了]',
                        src: require("../assets/emoticons/50.gif")
                    },
                    {
                        alt: '[阴险]',
                        src: require("../assets/emoticons/51.gif")
                    },
                    {
                        alt: '[亲亲]',
                        src: require("../assets/emoticons/52.gif")
                    },
                    {
                        alt: '[吓]',
                        src: require("../assets/emoticons/53.gif")
                    },
                    {
                        alt: '[可怜]',
                        src: require("../assets/emoticons/54.gif")
                    },
                    {
                        alt: '[菜刀]',
                        src: require("../assets/emoticons/55.gif")
                    },
                    {
                        alt: '[西瓜]',
                        src: require("../assets/emoticons/56.gif")
                    },
                    {
                        alt: '[啤酒]',
                        src: require("../assets/emoticons/57.gif")
                    },
                    {
                        alt: '[篮球]',
                        src: require("../assets/emoticons/58.gif")
                    },
                    {
                        alt: '[乒乓]',
                        src: require("../assets/emoticons/59.gif")
                    },
                    {
                        alt: '[咖啡]',
                        src: require("../assets/emoticons/60.gif")
                    },
                    {
                        alt: '[饭]',
                        src: require("../assets/emoticons/61.gif")
                    },
                    {
                        alt: '[猪头]',
                        src: require("../assets/emoticons/62.gif")
                    },
                    {
                        alt: '[玫瑰]',
                        src: require("../assets/emoticons/63.gif")
                    },
                    {
                        alt: '[凋谢]',
                        src: require("../assets/emoticons/64.gif")
                    },
                    {
                        alt: '[嘴唇]',
                        src: require("../assets/emoticons/65.gif")
                    },
                    {
                        alt: '[爱心]',
                        src: require("../assets/emoticons/66.gif")
                    },
                    {
                        alt: '[心碎]',
                        src: require("../assets/emoticons/67.gif")
                    },
                    {
                        alt: '[蛋糕]',
                        src: require("../assets/emoticons/68.gif")
                    },
                    {
                        alt: '[闪电]',
                        src: require("../assets/emoticons/69.gif")
                    },
                    {
                        alt: '[炸弹]',
                        src: require("../assets/emoticons/70.gif")
                    },
                    {
                        alt: '[刀]',
                        src: require("../assets/emoticons/71.gif")
                    },
                    {
                        alt: '[足球]',
                        src: require("../assets/emoticons/72.gif")
                    },
                    {
                        alt: '[瓢虫]',
                        src: require("../assets/emoticons/73.gif")
                    },
                    {
                        alt: '[便便]',
                        src: require("../assets/emoticons/74.gif")
                    },
                    {
                        alt: '[月亮]',
                        src: require("../assets/emoticons/75.gif")
                    },
                    {
                        alt: '[太阳]',
                        src: require("../assets/emoticons/76.gif")
                    },
                    {
                        alt: '[礼物]',
                        src: require("../assets/emoticons/77.gif")
                    },
                    {
                        alt: '[拥抱]',
                        src: require("../assets/emoticons/78.gif")
                    },
                    {
                        alt: '[强]',
                        src: require("../assets/emoticons/79.gif")
                    },
                    {
                        alt: '[弱]',
                        src: require("../assets/emoticons/80.gif")
                    },
                    {
                        alt: '[握手]',
                        src: require("../assets/emoticons/81.gif")
                    },
                    {
                        alt: '[胜利]',
                        src: require("../assets/emoticons/82.gif")
                    },
                    {
                        alt: '[抱拳]',
                        src: require("../assets/emoticons/83.gif")
                    },
                    {
                        alt: '[勾引]',
                        src: require("../assets/emoticons/84.gif")
                    },
                    {
                        alt: '[拳头]',
                        src: require("../assets/emoticons/85.gif")
                    },
                    {
                        alt: '[差劲]',
                        src: require("../assets/emoticons/86.gif")
                    },
                    {
                        alt: '[爱你]',
                        src: require("../assets/emoticons/87.gif")
                    },
                    {
                        alt: '[NO]',
                        src: require("../assets/emoticons/88.gif")
                    },
                    {
                        alt: '[OK]',
                        src: require("../assets/emoticons/89.gif")
                    },
                    {
                        alt: '[爱情]',
                        src: require("../assets/emoticons/90.gif")
                    },
                    {
                        alt: '[飞吻]',
                        src: require("../assets/emoticons/91.gif")
                    },
                    {
                        alt: '[跳跳]',
                        src: require("../assets/emoticons/92.gif")
                    },
                    {
                        alt: '[发抖]',
                        src: require("../assets/emoticons/93.gif")
                    },
                    {
                        alt: '[怄火]',
                        src: require("../assets/emoticons/94.gif")
                    },
                    {
                        alt: '[转圈]',
                        src: require("../assets/emoticons/95.gif")
                    },
                    {
                        alt: '[磕头]',
                        src: require("../assets/emoticons/96.gif")
                    },
                    {
                        alt: '[回头]',
                        src: require("../assets/emoticons/97.gif")
                    },
                    {
                        alt: '[跳绳]',
                        src: require("../assets/emoticons/98.gif")
                    },
                    {
                        alt: '[投降]',
                        src: require("../assets/emoticons/99.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/100.gif")
                    },
                    {
                        alt: '[乱舞]',
                        src: require("../assets/emoticons/101.gif")
                    },
                    {
                        alt: '[献吻]',
                        src: require("../assets/emoticons/102.gif")
                    },
                    {
                        alt: '[左太极]',
                        src: require("../assets/emoticons/103.gif")
                    },
                    {
                        alt: '[右太极]',
                        src: require("../assets/emoticons/104.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/105.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/106.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/107.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/108.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/109.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/110.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/111.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/112.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/113.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/114.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/115.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/116.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/117.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/118.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/119.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/120.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/121.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/122.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/123.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/124.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/125.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/126.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/127.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/128.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/129.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/130.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/131.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/132.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/133.gif")
                    },
                    {
                        alt: '[激动]',
                        src: require("../assets/emoticons/134.gif")
                    }
                ]
            }
        ];
        this.editor.customConfig.uploadImgServer = this.uploadUrl;
        this.editor.customConfig.uploadFileName = "imgFile";
        if (menus.length) {
            this.editor.customConfig.menus = menus;
        } else {
            this.editor.customConfig.menus = [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'italic',  // 斜体
                'underline',  // 下划线
                'strikeThrough',  // 删除线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                'link',  // 插入链接
                'list',  // 列表
                'justify',  // 对齐方式
                'quote',  // 引用
                'emoticon',  // 表情
                'image',  // 插入图片
            ]
        }

        this.editor.create();
    }
    getWEHtml() {
        return this.editor.txt.html();
    }

    //发送完消息，清空文本域内容
    getNull() {
        return this.editor.txt.clear();
    }

    getWEText() {
        return this.editor.txt.text();
    }

    setWEHtml(val) {
        this.editor.txt.html(val)
    }
}

export default wEditor;